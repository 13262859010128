const body = document.querySelector("body");
const header = document.querySelector("#header .navbar");
const hero = document.getElementById("hero");
const main = document.querySelector("main");

const pyAbundance = 20;

export function headerPadding() {
  if (hero) {
    hero.style.paddingBlock = `${header.clientHeight + pyAbundance}px`;
  } else {
    main.style.paddingTop = `${header.clientHeight}px`;
  }
}

const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

export function scrollDownUp() {
  const currentScroll = window.scrollY;

  if (currentScroll <= 100) {
    header.classList.remove(scrollUp);
    return;
  }

  if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
    // down
    header.classList.remove(scrollUp);
    header.classList.add(scrollDown);
  } else if (
    currentScroll < lastScroll &&
    header.classList.contains(scrollDown)
  ) {
    // up
    header.classList.remove(scrollDown);
    header.classList.add(scrollUp);
  }
  lastScroll = currentScroll;
}

// Open Navbar
const collapseHeader = document.getElementById("navbarSupportedContent");
const addAfter = document.querySelector("footer");

collapseHeader.addEventListener("show.bs.collapse", (event) => {
  body.classList.add("overflow-y-hidden");

  let newDiv = document.createElement("div", "");
  newDiv.classList.add("navbar-backdrop", "appearing");

  addAfter.after(newDiv);
});

collapseHeader.addEventListener("shown.bs.collapse", (event) => {
  // document.querySelector(".navbar-backdrop").classList.remove("appearing");
});

collapseHeader.addEventListener("hide.bs.collapse", (event) => {
  document.querySelector(".navbar-backdrop").classList.remove("appearing");
  document.querySelector(".navbar-backdrop").classList.add("leaving");
});

collapseHeader.addEventListener("hidden.bs.collapse", (event) => {
  document.querySelector(".navbar-backdrop").classList.remove("leaving");
  body.classList.remove("overflow-y-hidden");
  document.querySelector(".navbar-backdrop").remove();
});
