import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

const textInfiniteLoop = document.querySelector(
  ".text-infinite-loop-container"
);
if (textInfiniteLoop) {
  gsap.to(textInfiniteLoop, {
    scrollTrigger: {
      trigger: ".text-infinite-loop-container",
      scrub: 2,
      markers: false,
    },

    ease: "none",
    xPercent: 100,
  });
}

const perspectiveCard = document.getElementById("perspective-card");
if (perspectiveCard) {
  gsap.from(perspectiveCard, {
    scrollTrigger: {
      trigger: perspectiveCard,
      start: "top bottom",
      end: "top+=10% bottom",
      scrub: 1,
      markers: false,
    },

    transformStyle: "preserve-3d",
    rotateX: "-4deg",
    y: "20%",
    scale: 1.1,
  });
}

const animationContainer = document.getElementById("animation-container");
if (animationContainer) {
  gsap.from(
    "#animation-container .list-group.list-group--line .list-group-item",
    {
      scrollTrigger: {
        trigger: "#animation-container",
        start: "top center",
        markers: false,
        toggleActions: "play reverse play reverse",
      },
      opacity: 0,
      yPercent: 50,
      ease: "power1.inOut",

      stagger: {
        grid: [11, 1],
        each: 1,
        from: "start",
        axis: "y",
        amount: 0.8,
      },
    }
  );
}

// Animazione cos'è ESG
let whatIsEsgCards = document.querySelectorAll(
  ".d-flex.flex-column.justify-content-between.c-gap-3.c-gap-lg-5.h-100"
);

whatIsEsgCards.forEach((el) => {
  let tl = gsap.timeline({
    ease: "power1.inOut",
    scrollTrigger: {
      trigger: el,
      markers: false,
      start: "top bottom-=10%",
    },
  });

  let image = el.querySelector(".text-lg-center");
  let desc = el.querySelector(".mb-auto");
  let list = el.querySelectorAll(".mt-auto .list-group.list-group--line > *");

  tl.from(image, {
    opacity: 0,
  });

  tl.from(desc, {
    opacity: 0,
  });

  tl.from(list, {
    opacity: 0,
    yPercent: 50,
    stagger: {
      grid: [3, 1],
      from: "start",
      axis: "y",
      amount: 0.3,
    },
  });
});
