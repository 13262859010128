const body = document.querySelector("body");
let elementClicked = false;

let inactivityTime = function () {
  let time;
  window.onload = resetTimer;
  document.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer; // touchscreen presses
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer; // touchpad clicks
  document.onkeydown = resetTimer;
  document.onkeyup = resetTimer;
  window.onscroll = resetTimer;
  document.onkeypress = resetTimer;

  function inactivity() {
      if (!elementClicked) {
        body.classList.add('inactivity');
        document.querySelector(".energy-saving").classList.add('active');

        setTimeout(function() {
          document.querySelector(".energy-saving-content").classList.add('active');
        }, 50);
      } 
  }


  function resetTimer() {
      clearTimeout(time);
      time = setTimeout(inactivity, 20000)

  }
};

document.addEventListener("mousemove", () => {
  if(body.classList.contains("inactivity")) {

    document.querySelector(".energy-saving-content").classList.remove('active');

    setTimeout(function() {
      document.querySelector(".energy-saving").classList.remove('active');
      body.classList.remove("inactivity")
    }, 300);
  }
})

inactivityTime();


