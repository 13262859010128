// import Swiper JS
import Swiper from "swiper";
import { Pagination } from "swiper/modules";

const swiper = new Swiper(".magazine-slider", {
  modules: [Pagination],

  slidesPerView: 1.2,
  spaceBetween: 20,

  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    bulletActiveClass: "active",
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
    },

    1200: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
});
