import Atropos from "atropos";

const atroposEls = document.querySelectorAll(".my-atropos");

atroposEls.forEach((atroposEl) => {
  Atropos({
    el: atroposEl,
    shadow: false,
    highlight: false,
    rotateTouch: false,
    duration: 500,
  });
});
