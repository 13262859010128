import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Vivus from "vivus";

gsap.registerPlugin(ScrollTrigger);

// Hero Animation
let tl = gsap.timeline();

// Create svg animation
const svgLines = document.querySelector("#hero .svg-line svg");

// Start timeline
if (svgLines) {
  const svg = new Vivus(svgLines, {
    type: "delayed",
    duration: 100,
    animTimingFunction: Vivus.EASE_IN,
  });

  tl.from("#hero .title-animation", {
    opacity: 0,
    yPercent: 100,

    onComplete: function () {
      svg.play();
    },
  });

  tl.from(
    "#hero .btn-animation",
    {
      opacity: 0,
      yPercent: 100,
      delay: 1,
    },
    "<"
  );

  tl.from("#hero #canvas-item-1 img", {
    opacity: 0,
    yPercent: 50,
    duration: 1,
  });

  tl.from(
    "#hero #canvas-item-2 img",
    {
      opacity: 0,
      yPercent: 50,
    },
    "<"
  );
}
// End timeline
