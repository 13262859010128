import "bootstrap";

import "./atropos";
import "./gsap";
import "./gsap-card-fading";
import "./gsap-hero";
import { headerPadding, scrollDownUp } from "./header";
import "./magazine-slider";
import "./parallax-image";
import "./slider";
import "./vivus";
import "./wizard";
import "./inactivity-time"; 




window.addEventListener("DOMContentLoaded", () => {
  headerPadding();
});

window.addEventListener("resize", () => {
  headerPadding();
});

window.addEventListener("scroll", () => {
  scrollDownUp();
});

