// import Swiper JS
import Swiper from "swiper";
import { EffectFade, Pagination } from "swiper/modules";

const swiper = new Swiper(".green-marketing-slider", {
  modules: [Pagination, EffectFade],
  effect: "fade",

  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    bulletActiveClass: "active",
  },
});
