import Vivus from "vivus";

const svgLines = document.querySelectorAll(".svg-line svg");
svgLines.forEach((svgLine) => {
  new Vivus(svgLine, {
    type: "delayed",
    duration: 150,
    delay: 50,
    animTimingFunction: Vivus.EASE_IN,
  });
});
