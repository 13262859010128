import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let cardFading = document.querySelectorAll(".card-fade");

cardFading.forEach((card) => {
  gsap.from(card, {
    opacity: 0,
    delay: 0.3,

    scrollTrigger: {
      trigger: card,
      markers: false,
      start: "top bottom-=5%",
      end: "bottom+=10% top+=5%",
      toggleActions: "play reverse play reverse",
    },
  });
});
