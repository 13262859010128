import Vivus from "vivus";

const wizardWrapper = document.querySelector(".wizard-wrapper");

if (wizardWrapper) {
  // Get All Items
  const itemsList = document.querySelectorAll(
    ".wizard-items-list .wizard-item"
  );

  //const line = document.querySelector(".wizard-steps-line"); // Get lines container
  //const lineList = line.querySelectorAll(".wizard-line"); // Get lines

  // Check input not empty
  function checkClicked(step) {
    const btnWizard = itemsList[step].querySelector("button.wizard-button");
    const checks = itemsList[step].querySelectorAll(
      ".form-check input[required]"
    );
    const selects = itemsList[step].querySelectorAll(".form-group select");
    const textArea = itemsList[step].querySelector("textarea.form-control");
    const inputs = itemsList[step].querySelectorAll(
      "input.form-control[required]"
    );
    const svgLine = itemsList[step].querySelector(".wizard-item .svg-line svg");
    const submit = itemsList[step].querySelector("button[type=submit]");

    // Check
    checks.forEach((check) => {
      check.addEventListener("click", () => {
        if (check.checked == true) {
          btnWizard.disabled = false;
        }
      });
    });

    // Select
    const selectChecked = [];
    selects.forEach((select) => {
      select.addEventListener("change", () => {
        selectChecked.push(select);

        if (selectChecked.length == selects.length) {
          btnWizard.disabled = false;
        }
      });
    });

    // TextArea
    if (textArea) {
      if (checks) {
        textArea.disabled = true;

        checks.forEach((check) => {
          check.addEventListener("click", () => {
            console.log(check.value);
            if (check.value == "true") {
              textArea.disabled = false;
            } else {
              textArea.disabled = true;
            }
          });
        });
      }
    }

    // Input
    const inputHasValue = [];
    inputs.forEach((input) => {
      input.addEventListener("keyup", (e) => {
        let arrPosition = inputHasValue.indexOf(e.target);

        if (e.target.value && !inputHasValue.includes(e.target)) {
          if (!e.shiftKey) {
            inputHasValue.push(input);
          }
        } else if (
          inputHasValue.includes(e.target) &&
          e.target.value.length == 0
        ) {
          inputHasValue.splice(arrPosition, 1);
        }

        if (inputHasValue.length == inputs.length) {
          btnWizard.disabled = false;
        } else {
          btnWizard.disabled = true;
        }
      });
    });

    // Svg
    if (svgLine) {
      let svg = new Vivus(svgLine, {
        type: "delayed",
        duration: 50,
        animTimingFunction: Vivus.EASE_IN,
      });

      svg.play();
    }

    if (submit) {
      submit.addEventListener("click", (e) => {
        e.preventDefault();
      });
    }
  }

  function switchWizardItem(number) {
    if (number == 1) {
      const radioValues = document.querySelectorAll("#step-1 .form-check-input")
      const step2Btn = document.getElementById("change-step-btn");
      const back4Btn = document.getElementById("back-step4-btn");
      const back5Btn = document.getElementById("back-step5-btn");

      radioValues.forEach(radioValue => {
        radioValue.addEventListener("click", () => {
          if(radioValue.value == "produzione") {
            step2Btn.setAttribute("onclick", "switchWizardItem(3)")
            back4Btn.setAttribute("onclick", "switchWizardItem(3)")
            back5Btn.setAttribute("onclick", "switchWizardItem(4)")
          } else if(radioValue.value == "commercio-distribuzione"){
            step2Btn.setAttribute("onclick", "switchWizardItem(4)")
            back4Btn.setAttribute("onclick", "switchWizardItem(2)")
            back5Btn.setAttribute("onclick", "switchWizardItem(4)")
          } else if(radioValue.value == "servizi"){
            step2Btn.setAttribute("onclick", "switchWizardItem(5)")
            back5Btn.setAttribute("onclick", "switchWizardItem(2)")
          }
        })
        
      });


      // console.log(radioValue)
      // First Step
      itemsList.forEach((item) => {
        item.classList.remove("active");

        //line.classList.remove("opacity-0");
        itemsList[number].classList.add("active");
      });

      checkClicked(number);
    } else if (number == 8) {
      // Last Step
      const lastStepBg = document.querySelector(".wizard-bg"); // DotBG

      //line.classList.add("opacity-0"); // Remove Live
      lastStepBg.classList.remove("opacity-0"); // Show dot BG

      itemsList.forEach((item) => {
        item.classList.remove("active");
      });

      itemsList[number].classList.add("active");

      checkClicked(number);
    } else {
      // Middle Steps
      itemsList.forEach((item) => {
        item.classList.remove("active");
      });

      /* lineList.forEach((line) => {
        line.classList.remove("active");
      }); */

      itemsList[number].classList.add("active");

      //lineList[number - 1].classList.add("active");

      checkClicked(number);
    }
  }

  window.switchWizardItem = switchWizardItem;
}
